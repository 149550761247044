import $ from 'jquery';
window.$ = window.jQuery = $;

window.bootstrap = require('bootstrap');

/**
 * Custom Codes
 */
$(document).delegate('.ajax-open-modal', 'click', function(e){
    e.preventDefault();

    const ajaxUrl = $(this).attr('data-url');
    let defaultModal = new bootstrap.Modal(document.getElementById('modal-default'));

    $.ajax({
        type: 'GET',
        url: ajaxUrl,
        beforeSend:function(request) {
            //
        },
        success: function(response){
            $('#modal-default .modal-body').html(response);
            defaultModal.show();
            //
        },
        error: function(jqXhr){
            alert(jqXhr.responseJSON.message);
        },
    });
});
